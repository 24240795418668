<template>
    <div>
        <!-- 头部 -->
        <Header></Header>
        <!-- 内容区 -->
        <transition name="moveCartoon" appear>
            <div style="min-height:100vh">
                <router-view :key="$route.fullPath" />
            </div>
        </transition>
        <!-- 内容区 end -->
    </div>
</template>

<script>
import Header from '@/components/layout/Header.vue'
export default {
    name: 'Home',
    components: {
        Header,
        // Footer,
        // Sidebar,
        // Login,
        // SideNavBar,
        // SearchModle,
        // Loading,
        // Notice,
    },
    data() {
        return {

        }
    }
}
</script>

<style lang="scss" scoped>
.moveCartoon-enter-active {
    animation: move .3s;
}

.moveCartoon-leave-active {
    animation: move .3s reverse;
}

@keyframes move {
    from {
        transform: translateX(-100%);
    }

    to {
        transform: translate(0);
    }
}
</style>