<template>
    <header class="header">
        <div id="pre" :style="{ width: widthPre + '%' }"></div>
        <nav>
            <div class="logo">
                <div class="logoBox">
                    <router-link class="binda" :to="'/'">
                        <img src="@/assets/logo.png" class="logo" alt="" />
                        <span class="name">前端探索者</span>
                    </router-link>
                </div>
            </div>

            <div class="rightBox">
                <ul class="starlist">
                    <li :class="path == '/' ? 'active' : ''">
                        <span>
                            <router-link :to="'/'">
                                首页
                            </router-link>
                        </span>
                    </li>
                    <li :class="path == '/categorys' ? 'active' : ''">
                        <span>
                            <router-link :to="'/categorys'">
                                文章分类
                            </router-link>
                        </span>
                    </li>
                    <li :class="path == '/note' ? 'active' : ''">
                        <span>
                            <router-link :to="'/note'">
                                笔记
                            </router-link>
                        </span>
                    </li>
                    <li :class="path == '/about' ? 'active' : ''">
                        <span>
                            <router-link :to="'/about'">
                                关于本站
                            </router-link>
                        </span>
                    </li>
                </ul>
                <div class="otherbox">
                    <button class="search iconfont icon-sousuoxiao"></button>
                    <button class="addsq">加入书签</button>
                </div>
            </div>
        </nav>
        
    </header>
</template>

<script>
export default {
    name: "App",
    data() {
        return {
            widthPre: '',
            path: null,
        };
    },
    created() {
        this.path = this.$route.path
        
    },
    methods: {
        scrollFn() {
            let page = document.body.scrollHeight
            let client = document.documentElement.clientHeight
            let comend = page - client
            let scrTop = document.documentElement.scrollTop
            this.widthPre = Math.round(scrTop / comend * 10000) / 100
        },
    }
};
</script>

<style scoped lang="scss">
#pre {
    position: fixed;
    top: 0;
    height: 2px;
    background-image: -webkit-linear-gradient(0deg, #3ca5f6 0, #a86af9 100%);
}
.header{
    width: 100%;
    height:60px;
    background: #fff;
    nav{
        height: 100%;
        display:flex;
        align-items: center;
        width: 1200px;
        margin: 0 auto;
        justify-content: space-between;
        .binda{
            display: flex;
            align-items: center;
            .logo{
                width:40px;
                height:40px;
                margin-right: 5px;
            }
            .name{
                color: #f56c6c;
                font-size: 22px;
            }
        }
        .rightBox{
            width: 980px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            .starlist{
                display: flex;
                align-items: center;
                li{
                    padding: 0 25px;
                    display: inline-flex;
                    align-items: center;
                    height: 60px;
                    position: relative;
                    a{
                        font-size: 16px;
                        color: #71777c;
                    }
                    &::before{
                        display: block;
                        content: " ";
                        width: 0px;
                        height: 4px;
                        border-radius: 2px;
                        position: absolute;
                        top: 0;
                        background: #f56c6c;
                        left: 50%;
                        transform: translateX(-50%);
                        transition: width 0.3s linear;
                     }
                    &.active{
                        a{
                            color: #f56c6c;
                            font-weight: bold;
                        }
                        &::before{
                            width: 60%;
                        }
                    }
                    &:hover{
                        &::before{
                            display: block;
                            content: " ";
                            width: 60%;
                            height: 4px;
                            border-radius: 2px;
                            position: absolute;
                            top: 0;
                            background: #f56c6c;
                            left: 50%;
                            transform: translateX(-50%);
                            transition: width 0.3s linear;
                        }
                        a{
                            color: #f56c6c;
                            font-weight: bold;
                        }
                    }
                    
                }
            }
            .otherbox{
                display:flex;
                align-items: center;
                .search{
                    font-size: 20px;
                    color:var(--text-color);
                }
                .addsq{
                    margin-left: 20px;
                    font-size: 14px;
                    border: 2px solid var(--theme-color);
                    color: var(--theme-color);
                    border-radius: 5px;
                    height: 30px;
                    line-height: 8px;
                    letter-spacing: 2px;
                    font-weight: bold;
                    padding: 0 15px;
                }
            }
        }
    }
    
}



</style>