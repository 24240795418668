<template>
    <div id="app">
        <router-view :key="$route.fullPath" />
    </div>
</template>

<script>
import { setSkin, getSkin } from "@/utils/cookieUtil";
export default {
    name: "App",
    beforeCreate() {
        if (getSkin() == null) {
            setSkin("shallow");
        } else {
            setSkin(getSkin());
        }
    },
    mounted() {
      this.$setSkin();
    }
};
</script>


<style lang="scss" scoped>

</style>
