import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import jsCookie from 'js-cookie'
import { setSkin } from '@/utils/skin'; //设置皮肤
import hljs from 'highlight.js';
import 'highlight.js/styles/atom-one-dark-reasonable.css' //样式

import '@/assets/iconfont/iconfont.css';
import '@/styles/index.scss';


Vue.config.productionTip = false

Vue.prototype.$cookie = jsCookie;  // 在页面里可直接用 this.$cookie 调用
Vue.prototype.$setSkin = setSkin;

Vue.directive('highlight', function (el) {
  let blocks = el.querySelectorAll('pre code');
  blocks.forEach((block) => {
    hljs.highlightBlock(block)
  })
})
Vue.prototype.$hljs = hljs;

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
