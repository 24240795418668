import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '@/views/home/home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    children: [
      {
        path: "/",
        component: () => import("@/views/home/index.vue"),
        meta: {
          title: "文章分类"
        }
      },
      {
        path: "/categorys",
        component: () => import("@/views/category/Category.vue"),
        meta: {
          title: "文章分类"
        }
      },
      {
        path: "/note",
        component: () => import("@/views/note/index.vue"),
        meta: {
          title: "笔记"
        }
      },
      {
        path: "/about",
        component: () => import("@/views/about/index.vue"),
        meta: {
          title: "关于本站"
        }
      },
    ]
  },
  {
    path: '*',
    name: 'NotFound',
    component: () => import('@/views/404/404.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
});


export default router
